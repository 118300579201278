import Swiper from "swiper";
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

let slider;

const navigation = document.querySelector(".navigation");
const navigationTrigger = document.querySelector(".navigation__trigger");
const datepicker = document.querySelectorAll(".datepicker");
const swiperContainer = document.querySelectorAll(".swiper-container");
const videoIframe = document.querySelectorAll("iframe");

const resizeVideoFrame = () => {
	videoIframe.forEach((iframe) => {
		const width = iframe.scrollWidth;
		const height = (width / 100) * 56.25;
		iframe.style.height = height + "px";
	});
};

flatpickr.localize(German);

if (datepicker.length) {
	datepicker.forEach((element) => {
		flatpickr(element, {
			dateFormat: "d.m.Y",
			minDate: new Date(),
			plugins: [new monthSelectPlugin({ shorthand: false, dateFormat: "F Y", altFormat: "F Y" })],
		});
	});
}

if (navigation && navigationTrigger) {
	navigationTrigger.addEventListener("click", () => {
		if (navigation.classList.contains("open")) {
			document.querySelector("body").style.overflow = "auto";
			navigation.classList.remove("open");
		} else {
			document.querySelector("body").style.overflow = "hidden";
			navigation.classList.add("open");
		}
	});
}

if (swiperContainer.length) {
	swiperContainer.forEach((container) => {
		slider = new Swiper(container, {
			autoHeight: container.classList.contains("autoheight"),
			loop: true,
			autoplay: { delay: 5000 },
			slidesPerView: 1,
			spaceBetween: 0,
			navigation: {
				nextEl: container.parentElement.querySelector(".swiper-button-next"),
				prevEl: container.parentElement.querySelector(".swiper-button-prev"),
			},
			disableOnInteraction: true,
		});
	});
}

if (videoIframe.length) {
	window.addEventListener("resize", () => {
		resizeVideoFrame();
	});
}

const accordionTrigger = document.querySelectorAll(".product__detail--header");
if (accordionTrigger) {
	Array.from(accordionTrigger).forEach((trigger) => {
		trigger.addEventListener("click", () => {
			console.log("click");
			const parent = trigger.parentElement;
			const accordionContent = parent.querySelector(".product__detail--body");
			parent.classList.toggle("open");
		});
	});
}

const qtyChanger = document.querySelector(".product__qty--input");
if (qtyChanger) {
	const increase = qtyChanger.querySelector(".increase");
	const decrease = qtyChanger.querySelector(".decrease");
	const input = qtyChanger.querySelector("input");
	increase.addEventListener("click", () => {
		input.value = parseInt(input.value) + 1;
	});

	decrease.addEventListener("click", () => {
		if (parseInt(input.value) == 1) return;
		input.value = parseInt(input.value) - 1;
	});
}

const productGallery = document.querySelector(".product__gallery");
if (productGallery) {
	const prductThumbnail = document.querySelector(".product__thumbnail");
	const gallery = Array.from(productGallery.querySelectorAll("picture"));
	gallery.forEach((picture) =>
		picture.addEventListener("click", () => {
			gallery.forEach((img) => img.classList.remove("active"));
			picture.classList.add("active");
			prductThumbnail.querySelector("picture").innerHTML = picture.innerHTML;
		})
	);
}
